<template>
  
  <div v-if="props.user && props.week" class="week_comment">

    <WeekwideViewActions 
      :week="props.week" 
      :user="props.user"
      />
    <!-- <div class="divider">

      <div class="title">
        {{ $t('week_view_commented_by') }} 
      </div> 

      <NuxtLink class="label_author" :to="props.user.link">
        <img 
          :src="props.user.avatar_little"      
          class="user_avatar" 
          :alt="props.user.name" 
          >
        {{ props.user.name }}
        <div class="detail">
          {{ $dayjs(props.week.add_date).fromNow() }}          
        </div>
      </NuxtLink>

    </div> -->


    <div class="text">
      
      <!-- {{ props.user.name }} {{ props.week.text }} -->

      <UiTextTruncate :week="props.week" :visibleLines="3" />

    </div>

    <div class="date">
      {{ $dayjs(props.week.add_date).fromNow() }}
    </div>

    
  
  </div>
  
</template>

<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  }
})


</script>




<style scoped>
 
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

@container pb (max-width: 600px) {

  
}
</style>
