<template>
  <div>
    <span ref="elText" :class="{'txt': true, 'expanded': expanded}">
      {{ textContent }}
    </span>
    <span class="more" v-if="!expanded && showMore" @click="expanded = true">more</span>
  </div>
</template>

<script setup>


const props = defineProps({
  text: {
    type: String,
    required: false
  },
  visibleLines: {
    type: Number,
    default: 3
  },
  week: {
    type: Object,
    required: false
  }
})
const elText = ref(null)
const textContent = ref(props.text || props.week?.text)
const expanded = ref(false)
const showMore = ref(false)



onMounted(() => {
  const lineHeight = 20 
  // const containerHeight = document.querySelector('p')?.scrollHeight
  const containerHeight = elText.value.scrollHeight
  showMore.value = containerHeight > lineHeight * props.visibleLines
})

</script>

<style scoped>
.txt {
  line-height: 20px;
  max-height: 72px; 
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.txt.expanded {
  display: block;
  max-height: none;
}

.more{
  cursor: pointer;
  color: var(--un-text-color-gray);
  font-weight: bold;
}
</style>